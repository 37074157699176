import React, {useEffect, useRef, useState} from 'react';
import NavbarTop from "./NavbarTop";
import {createAcademy, getPosts} from "../api/axios";
import DataTable from 'react-data-table-component';

const Academy = () => {
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [price, setPrice] = useState("");
  const [link, setLink] = useState("");
  const [image, setImage] = useState(null);
  const [imagePrev, setImagePrev] = useState(null);
  const [faqs, setFaqs] = useState([]);
  const inputFile = useRef(null);

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  useEffect(() => {
    getPosts().then(value => setFaqs(value?.data?.data))
  }, [0]);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(event.target.files[0]);
      setImagePrev(URL.createObjectURL(event.target.files[0]));
    }
  }

  async function onSubmit(e) {
    try {
      let state = {
        title: title,
        text: text
      }

      const model = JSON.stringify(state);

      let formData = new FormData();
      formData.append('file', image);
      formData.append('model', model);

      await createAcademy(formData);
      alert("Saved")
      window.location.reload();
    } catch (error) {
      console.log(error);
      alert(error?.response?.data?.validationErrors)
    }
  }

  const columns = [
    {
      name: 'Title',
      selector: row => row?.title?.length >= 50 ? row.title.slice(0, 50) + "..." : row.title,
    },
    {
      name: 'Text',
      selector: row => row?.text?.length >= 200 ? row.text.slice(0, 200) + "..." : row.text,
    },
    {
      name: 'Update',
      selector: row => <a href={`/academy/${row.id}`} className="btn-update">Update</a>,
    },
  ]

  return (<>
    <NavbarTop/>
    <div className="container" style={{marginTop: "20px"}}>
      <div className="brands-create">
        <div className="settings__content">
          <span className="settings__headline">
            Add New Post
          </span>
          <div className="settings__wrapper website__wrapper">
            <input
              onChange={e => setTitle(e.target.value)}
              className="settings__input"
              type="text"
              placeholder="Title"
              required
            />
            <textarea
              maxLength={8000}
              style={{height: "25rem"}}
              onChange={e => setText(e.target.value)}
              className="settings__input"
              placeholder="Content"
              required
            />
            <div className="d-flex flex-row gap-5">
              <div className="d-flex flex-column w-50" style={{gap: "2.5rem"}}>

                <label htmlFor="images" className="drop-container" id="dropcontainer">
                  <button className="upload-image-button" onClick={onButtonClick}>Add Academy Image</button>
                </label>
                <input onChange={onImageChange} className="align-rigfht" ref={inputFile} style={{display: 'none'}} type='file' id='file'/>

              </div>

              <div className="d-flex justify-content-center">
                {imagePrev !== null ? <img className="w-100" style={{maxHeight: "250px"}} alt="preview image" src={"data:image/jpeg;base64," + imagePrev}/> : ""}
              </div>
            </div>
            <button onClick={onSubmit}
                    type="submit"
                    className="btn btn-primary btn-brand-save"
                    disabled={title.trim() === "" || text.trim() === ""}>
              Save Post
            </button>
          </div>
        </div>
      </div>
      <div className="service-faq-list" style={{border: "5px solid #232323"}}>
        <DataTable
          title="Posts"
          highlightOnHover={true}
          columns={columns}
          data={faqs}
          striped
          theme="dark"
          pagination={false}
          noDataComponent="No Results"
        />
      </div>
    </div>
  </>)

}
export default Academy;