import axios from 'axios';

const BASE_URL = 'https://dashboard.asferrari.com/api/v1';

export default axios.create({
  baseURL: BASE_URL
});
const to = localStorage.getItem("auth")

export const getTuning = (id) => {
  return axios.get(BASE_URL + `/tuning/get/${id}`, {
    headers: { 'Authorization': `Bearer ${to}` }
  });
}

// BRANDS
export const getBrands = () => {
  return axios.get(BASE_URL + "/brand/get-brands", {
    headers: { 'Authorization': `Bearer ${to}` }
  });
}
export const getBrandById = (brandId) => {
  return axios.get(BASE_URL + `/brand/${brandId}`, {
    headers: { 'Authorization': `Bearer ${to}` }
  });
}
export const createBrand = (brandName) => {
  return axios.post(BASE_URL + "/brand",
    JSON.stringify({ 'brandName': brandName }),
    {
      headers: {
        'Authorization': `Bearer ${to}`,
        'Content-Type': 'application/json'
      },
    }
  );
}
export const updateBrand = (brandId, brandName) => {
  return axios.post(BASE_URL + `/brand/update/${brandId}/${brandName}`,
    {},
    {
      headers: {
        'Authorization': `Bearer ${to}`,
        'Content-Type': 'application/json'
      },
    }
  );
}


// MODELS
export const getModelsByBrandName = (brandName) => {
  return axios.get(BASE_URL + `/model/get-models/${brandName}`, {
    headers: { 'Authorization': `Bearer ${to}` }
  });
}
export const getModelById = (brandId) => {
  return axios.get(BASE_URL + `/model/${brandId}`, {
    headers: { 'Authorization': `Bearer ${to}` }
  });
}

export const getProductById = (productId) => {
  return axios.get(BASE_URL + `/product/get/${productId}`, {
    headers: { 'Authorization': `Bearer ${to}` }
  });
}

export const getServiceById = (productId) => {
  console.log(productId);
  return axios.get(BASE_URL + `/service/get/${productId}`, {
    headers: { 'Authorization': `Bearer ${to}` }
  });
}

export const getDealerById = (dealerId) => {
  return axios.get(BASE_URL + `/dealer/get/${dealerId}`, {
    headers: { 'Authorization': `Bearer ${to}` }
  });
}
export const createModel = (brandName, modelName) => {
  return axios.post(BASE_URL + "/model",
    JSON.stringify({
      'brandName': brandName,
      'modelName': modelName
    }),
    {
      headers: {
        'Authorization': `Bearer ${to}`,
        'Content-Type': 'application/json'
      },
    }
  );
}
export const updateModel = (modelId, modelName) => {
  return axios.post(BASE_URL + `/model/update/${modelId}/${modelName}`,
    {},
    {
      headers: {
        'Authorization': `Bearer ${to}`,
        'Content-Type': 'application/json'
      },
    }
  );
}


// WEB SETTINGS


export const getWebSettings = () => {
  return axios.get(BASE_URL + `/website/get`, {
    headers: { 'Authorization': `Bearer ${to}` }
  });
}

export const getPosts = () => {
  return axios.get(BASE_URL + `/academy/list`, {
    headers: { 'Authorization': `Bearer ${to}` }
  });
}
export const updateWebSettings = (data) => {
  return axios.post(BASE_URL + `/website`,
    data,
    {
      headers: {
        'Authorization': `Bearer ${to}`,
        'Content-Type': 'application/json'
      },
    }
  );
}

export const createAcademy = (formData) => {
  return axios.post(BASE_URL + "/academy",
    formData,
    {
      headers: {
        'Authorization': `Bearer ${to}`,
        "Content-type": "multipart/form-data",
      },
    }
  );
}


export const updateSlider = (formData) => {
  return axios.post(BASE_URL + `/website/slider`,
    formData,
    {
      headers: {
        'Authorization': `Bearer ${to}`,
        "Content-type": "multipart/form-data",
      },
    }
  );
}

// SERVICE FAQs


export const getServiceFAQs = () => {
  return axios.get(BASE_URL + `/servicefaq/list`, {
    headers: { 'Authorization': `Bearer ${to}` }
  });
}

export const getProducts = () => {
  return axios.get(BASE_URL + `/product/list`, {
    headers: { 'Authorization': `Bearer ${to}` }
  });
}

export const getServices = () => {
  return axios.get(BASE_URL + `/service`, {
    headers: { 'Authorization': `Bearer ${to}` }
  });
}

export const getDealers = () => {
  return axios.get(BASE_URL + `/dealer/list`, {
    headers: { 'Authorization': `Bearer ${to}` }
  });
}

export const createProduct = (formData) => {
  return axios.post(BASE_URL + "/product",
    formData,
    {
      headers: {
        'Authorization': `Bearer ${to}`,
        "Content-type": "multipart/form-data",
      },
    }
  );
}

export const createService = (formData) => {
  return axios.post(BASE_URL + "/service",
    formData,
    {
      headers: {
        'Authorization': `Bearer ${to}`,
        "Content-type": "multipart/form-data",
      },
    }
  );
}

export const updateProduct = (formData, id) => {
  return axios.post(BASE_URL + `/product/update/${id}`,
    formData,
    {
      headers: {
        'Authorization': `Bearer ${to}`,
        "Content-type": "multipart/form-data",
      },
    }
  );
}
export const updateService = (formData, id) => {
  return axios.post(BASE_URL + `/service/update/${id}`,
    formData,
    {
      headers: {
        'Authorization': `Bearer ${to}`,
        "Content-type": "multipart/form-data",
      },
    }
  );
}

export const updateAcademy = (formData, id) => {
  return axios.post(BASE_URL + `/academy/update/${id}`,
    formData,
    {
      headers: {
        'Authorization': `Bearer ${to}`,
        "Content-type": "multipart/form-data",
      },
    }
  );
}
export const createServiceFAQs = (title, text) => {
  return axios.post(BASE_URL + "/servicefaq",
    JSON.stringify({
      'title': title,
      'text': text
    }),
    {
      headers: {
        'Authorization': `Bearer ${to}`,
        'Content-Type': 'application/json'
      },
    }
  );
}
export const updateServiceFAQs = (title, text, id) => {
  return axios.post(BASE_URL + `/servicefaq/update/${id}`,
    JSON.stringify({
      'title': title,
      'text': text
    }),
    {
      headers: {
        'Authorization': `Bearer ${to}`,
        'Content-Type': 'application/json'
      },
    }
  );
}

export const createDealer = (data) => {
  return axios.post(BASE_URL + "/dealer",
    data,
    {
      headers: {
        'Authorization': `Bearer ${to}`,
        'Content-Type': 'application/json'
      },
    }
  );
}
export const updateDealer = (data, id) => {
  return axios.post(BASE_URL + `/dealer/update/${id}`,
    data,
    {
      headers: {
        'Authorization': `Bearer ${to}`,
        'Content-Type': 'application/json'
      },
    }
  );
}

export const createTuning = (data) => {
  return axios.post(BASE_URL + `/tuning`,
    data,
    {
      headers: {
        'Authorization': `Bearer ${to}`,
        'Content-Type': 'application/json'
      },
    }
  );
}

export const createAbout = (data) => {
  return axios.post(BASE_URL + `/about`,
    data,
    {
      headers: {
        'Authorization': `Bearer ${to}`,
        'Content-Type': 'application/json'
      },
    }
  );
}

export const updateTuning = (id, data) => {
  return axios.post(BASE_URL + `/tuning/update/${id}`,
    data,
    {
      headers: {
        'Authorization': `Bearer ${to}`,
        'Content-Type': 'application/json'
      },
    }
  );
}

export const getTunings = (brand, model) => {

  return axios.get(BASE_URL + `/tuning/list/${brand}/${model}`, {
    headers: { 'Authorization': `Bearer ${to}` }
  });
}

export const getAcademy = (id) => {

  return axios.get(BASE_URL + `/academy/get/${id}`, {
    headers: { 'Authorization': `Bearer ${to}` }
  });
}

export const getAbout = () => {

  return axios.get(BASE_URL + `/about/get`, {
    headers: { 'Authorization': `Bearer ${to}` }
  });
}

export const deleteProduct = (id) => {

  return axios.post(BASE_URL + `/product/delete/${id}`, {
    headers: { 'Authorization': `Bearer ${to}` }
  });
}

export const deleteDealer = (id) => {

  return axios.post(BASE_URL + `/dealer/delete/${id}`, {
    headers: { 'Authorization': `Bearer ${to}` }
  });
}



export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true
});
